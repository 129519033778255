import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import Input from './modules/Input'
import Output from './modules/Output'
import Shopping from './modules/Shopping'
import Catering from './modules/Catering'
import Order from './modules/Order'
import Delivery from './modules/Delivery'
import Sale from './modules/Sale'
const modules = {
  Input,
  Output,
  Shopping,
  Catering,
  Order,
  Delivery,
  Sale,
}

const state = {
  sidebarShow: 'responsive',
  url_server_command: 'http://192.168.0.55/print_command/consumirapi.php',
  // url_server_command: 'http://192.168.0.36/print/consumirapi.php',
  sidebarMinimize: true,
  // url_base: 'https://sistema.laglorietarestaurant.com/api/',
  // url_base: 'https://sisrestaurante.reyfact.com/api/',
  // url_base: 'https://sistema.elranchotacna.com/api/',
  // url_base: 'https://sistema.muellesur.com/api/',
  // url_base: 'https://sistema.labizcotela.com/api/',
  // url_base: 'https://sistema.camanchacaperu.com/api/',
  url_base: 'https://sistema.restaurantfestivaldesabores.com/api/',
  // url_base: 'http://localhost:8000/',
}
const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  }
}



export default new Vuex.Store({
  state,
  mutations,
  modules
})