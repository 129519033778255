//to handle state
import EventBus from "@/assets/js/EventBus";
const state = {
 
    delivery_detail: [],
    total: {
        subtotal: parseFloat(0).toFixed(2),
        delivery: parseFloat(0).toFixed(2),
        discount: parseFloat(0).toFixed(2),
        total: parseFloat(0).toFixed(2),
        delivery_detail:'',
    },
}

//to handle state
const getters = {}

//to handle actions
const actions = {

    mLoadAddDeliveryDetail(context,letter) {
        context.commit('mAddDeliveryDetail',letter);
        context.dispatch('mLoadTotalDelivery');
    },

    mLoadEditDeliveryDetail(context,index){
        let detail = context.state.delivery_detail;
        detail[index].quantity = detail[index].quantity.length == 0 ? 0 : parseFloat(detail[index].quantity);
        detail[index].unit_price = detail[index].unit_price.length == 0 ? 0 : parseFloat(detail[index].unit_price);
        detail[index].total_price = parseFloat(detail[index].quantity) * parseFloat(detail[index].unit_price);

        detail[index].quantity = parseFloat(detail[index].quantity).toFixed(2);
        detail[index].unit_price = parseFloat(detail[index].unit_price).toFixed(2);
        detail[index].total_price = parseFloat(detail[index].total_price).toFixed(2);
        context.dispatch('mLoadTotalDelivery');
    },

    mLoadDeleteDeliveryDetail(context,index){
        context.commit('mDeleteDeliveryDetail',index);
        context.dispatch('mLoadTotalDelivery');
    },

    mLoadResetDeliveryDetail(context){
        context.commit('mResertDeliveryDetail');
        context.dispatch('mLoadTotalDelivery');
    },

    mLoadTotalDelivery(context){
        let detail = context.state.delivery_detail;
        let delivery_total = context.state.total;
        delivery_total.delivery = delivery_total.delivery.length == 0 || parseFloat(delivery_total.delivery) < 0 ? 0 : delivery_total.delivery;
        delivery_total.discount = delivery_total.discount.length == 0 || parseFloat(delivery_total.discount) < 0 ? 0 : delivery_total.discount;
        let subtotal = 0;
        let total = 0;
        for (let index = 0; index < detail.length; index++) {
            const element = detail[index];
            subtotal +=  parseFloat(element.total_price);
        }
        total = parseFloat(subtotal) + parseFloat(delivery_total.delivery) - parseFloat(delivery_total.discount);

        delivery_total.subtotal = parseFloat(subtotal).toFixed(2);
        delivery_total.delivery = parseFloat(delivery_total.delivery).toFixed(2);
        delivery_total.discount = parseFloat(delivery_total.discount).toFixed(2);
        delivery_total.total = parseFloat(total).toFixed(2);
        delivery_total.number_to_letters = '';

        context.commit('mChangeDeliveryTotal',delivery_total);
        EventBus.$emit('TotalDelivery');
    },

}

//to handle mutations
const mutations = {

    mAddDeliveryDetail(state, detail) {
        state.delivery_detail.push(detail);
    },
    mDeleteDeliveryDetail(state, index) {
        state.delivery_detail.splice(index,1);
    },
    mResertDeliveryDetail(state) {
        state.delivery_detail = [];
    },

    mChangeDeliveryTotal(state,total) {
        state.total = total;
    },


}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}