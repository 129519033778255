//to handle state
const state = {
    new_order_detail: [],
    total_new_order:'0.00',
    order_detail: [],
    total: '0.00',
}

//to handle state
const getters = {}

//to handle actions
const actions = {

    mLoadNewAddOrderDetail(context,letter) {
        console.log(letter)
        context.commit('mNewAddOrderDetail',letter);
        context.dispatch('mLoadTotalNewOrderDetail');
    },

    mLoadNewEditOrderDetail(context,index){
        let detail = context.state.new_order_detail;
        detail[index].quantity = detail[index].quantity.length == 0 ? 0 : parseFloat(detail[index].quantity);
        detail[index].quantity = parseFloat(detail[index].quantity);
        context.dispatch('mLoadTotalNewOrderDetail');
    },

    mLoadNewDeleteOrderDetail(context,index){
        context.commit('mNewDeleteOrderDetail',index);
        context.dispatch('mLoadTotalNewOrderDetail');
    },

    mLoadNewResetOrderDetail(context){
        context.commit('mNewResertOrderDetail');
        context.dispatch('mLoadTotalNewOrderDetail');
    },
    mLoadNewAddOrderDetailObservation(context,data) {
        context.commit('mNewAddOrderDetailObservation',data);
        context.dispatch('mLoadTotalNewOrderDetail');
    },
    mLoadTotalNewOrderDetail(context){
        let detail = context.state.new_order_detail;
        let total = 0;
        for (let index = 0; index < detail.length; index++) {
            const element = detail[index];
            total += parseFloat(element.total_price);
        }
        total = parseFloat(total).toFixed(2);
        context.commit('mTotalNewOrder',total);
        
    },


    










    mLoadAddOrderDetail(context,letter) {
        context.commit('mAddOrderDetail',letter);
        context.dispatch('mLoadTotalOrderDetail');
    },

    mLoadEditOrderDetail(context,index){
        let detail = context.state.order_detail;
        detail[index].quantity = detail[index].quantity.length == 0 ? 0 : parseFloat(detail[index].quantity);
        detail[index].quantity = parseFloat(detail[index].quantity);
        context.dispatch('mLoadTotalOrderDetail');
    },

    mLoadEditPriceOrderDetail(context,data){
        let detail = context.state.order_detail;
        detail[data.index].unit_price = parseFloat(data.unit_price);
        detail[data.index].quantity = parseFloat(detail[data.index].quantity);
        detail[data.index].total_price = parseFloat(detail[data.index].quantity) * parseFloat(detail[data.index].unit_price);

        detail[data.index].quantity = parseFloat(detail[data.index].quantity);
        detail[data.index].unit_price = parseFloat(detail[data.index].unit_price).toFixed(2);
        detail[data.index].total_price = parseFloat(detail[data.index].total_price).toFixed(2);
        context.dispatch('mLoadTotalOrderDetail');
    },


    mLoadEditNameOrderDetail(context,data){
        let detail = context.state.order_detail;
        detail[data.index].name = data.name;

    },

    mLoadTotalOrderDetail(context){
        let detail = context.state.order_detail;
        let total = 0;
        for (let index = 0; index < detail.length; index++) {
            const element = detail[index];
            total += parseFloat(element.total_price);
        }
        total = parseFloat(total).toFixed(2);
        context.commit('mTotal',total);
    },

    mLoadDeleteOrderDetail(context,index){
        context.commit('mDeleteOrderDetail',index);
        context.dispatch('mLoadTotalOrderDetail');
    },

    mLoadResetOrderDetail(context){
        context.commit('mResertOrderDetail');
        context.dispatch('mLoadTotalOrderDetail');
    },

}

//to handle mutations
const mutations = {

    mNewAddOrderDetail(state, detail) {
        state.new_order_detail.push(detail);
    },
    mNewDeleteOrderDetail(state, index) {
        state.new_order_detail.splice(index,1);
    },
    mNewResertOrderDetail(state) {
        state.new_order_detail = [];
    },
    mNewAddOrderDetailObservation(state, detail) {
        state.new_order_detail[detail.index].observations.push(detail.name);
    },
    mNewDeleteOrderDetailObservation(state, detail) {
        state.new_order_detail[detail.index].observations.splice(detail.index_observation);
    },
    mTotalNewOrder(state,total) {
        state.total_new_order = total;
    },




    


    mAddOrderDetail(state, detail) {
        state.order_detail.push(detail);
    },

    mDeleteOrderDetail(state, index) {
        state.order_detail.splice(index,1);
    },
    mResertOrderDetail(state) {
        state.order_detail = [];
    },
    mTotal(state,total) {
        state.total = total;
    },


}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}